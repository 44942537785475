import React, { useState } from 'react';
import { Background } from '..';
import { Link, useNavigate } from 'react-router-dom';
import PinInput from 'react-pin-input';
import { toast } from 'react-toastify';
import postService from '../../../Services/post-service';
import cookie from 'react-cookies'
import Button from '../../../Components/Input/Button';
import Loading from '../../../Components/Input/Loading';
const VerifyPin = () => {
    const navigator = useNavigate()
    const [open, setOpen] = useState(false)
    const [pin, setPin] =useState('')
   let user
   user = localStorage.getItem('user')
    let email = sessionStorage.getItem('sEmail')
    const userValue =  JSON.parse(user)
    const onSubmit = (e) =>{
        e.preventDefault()
       
        console.log(userValue.emailAddress)
         
          if(pin.length === 6){
            setOpen(true)
            postService.verifyEmail(pin, userValue.uuid).then(
              (response) => {
                  console.log(response.data)
                  setOpen(false)
                 if(response.data.status === '2000'){
                  cookie.save("jwttoken", response.data.data.jwtToken, {
                    path: "/",
                    expires: new Date(Date.now() + 60 * 10 * 1000),
                  });
    
                   toast.success('Email verified successfully')  
                  setTimeout(() => {
                    navigator('/')
                  }, 4000);
                 }else{
                  toast.error(response.data.description)
                 setTimeout(() => {
                  window.location.reload()
                }, 5000);
                 
                 }
                 
                },
                (error) => {
                  setOpen(false)
                  toast.error('Email validation failed')
                  setTimeout(() => {
                   window.location.reload()
                 }, 5000);
                   
                  }
            )
          } 
              // navigator('/createpin')
          console.log(pin)
      }
        const resendOtp = () =>{
          
            console.log('rtyyityfghgf')
            setOpen(true)
            postService.resendOtp(email).then(
              (response) => {
                  console.log(response.data)
                  setOpen(false)
                 if(response.data.status === '2000'){
                  
                   toast.success('Otp resent successfully !!')
                  setTimeout(() => {
                    window.location.reload()
                  }, 5000);
  
                 
                 }else{
                  toast.error(response.data.description)
                 setTimeout(() => {
                  window.location.reload()
                }, 5000);
                  // swal(response.data.description).then((value) => {
                  //   window.location.reload()
                  // });
                 
                 }
                 
                },
                (error) => {
                  setOpen(false)
                   toast.error('Otp resend failed!!')
                   setTimeout(() => {
                    window.location.reload()
                  }, 5000);
                  }
            )
          
        }

    return (
        <Background padding="none">
                <Loading open={open}/>
            <div className='w-full flex items-center justify-between bg-white p-3 font-bold text-custom-black text-[1.4rem]'>
                <Link to="/Signup" className='font-bold text-3xl cursor-pointer ml-[-10px]'>
                    &larr;
                </Link>

                <p>Verify Account</p>

                <Link className='font-bold text-3xl cursor-pointer'>
                    &times;
                </Link>
            </div>
            <div className='my-[30px]'>
                <h1 className='text-custom-black font-[700]'>Enter OTP</h1>
                <p className='text-custom-gray font-[700] '>Please enter the 6-digit code sent to your email address</p>
            </div>
          <form onSubmit={onSubmit}>
          <div className='h-[140px]'>
            <PinInput 
                length={6}
                initialValue={pin}
                secret  
                onChange={(value, index) => {
                    setPin(value)
                }} 
                type="numeric" 
                inputMode="number"
                style={{padding: '8px', }}  
                inputStyle={{borderColor: '#00BC67',backgroundColor:'#FAFBFC', borderRadius: 8,}}
                inputFocusStyle={{borderColor: '#00BC67', backgroundColor:'#CCF2E1'}}
                onComplete={(value, index) => {
                    pin(value)
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
            </div>

            <div className=' grid grid-cols-1'>
                <Button
                    name={'Verify mail'}
               />
               </div>
          </form>
            
               <p onClick={resendOtp} className='  text-primary text-center my-3'>Resend otp</p>
        </Background>
    );
};

export default VerifyPin;
