import React, { useEffect, useState } from 'react'
import Naira from '../../../Assets/Images/Naira.png'
import Modal from '../../Card/modalCont'
import CustomInput from '../../Input/custom.jsx'
import CustomTextField from '../../Input/input.jsx'
import CustomizedSwitches from '../../Input/switch.jsx'
import cancelIcon from '../../../Assets/Images/cancelIcon.svg'
import getServices from '../../../Services/get-service.js'
import { toast } from 'react-toastify'
import Loading from '../../Input/Loading.js'
import postService from '../../../Services/post-service.js'
import { usePaystackPayment } from 'react-paystack';
const Cable = ({closeIcon}) => {
  
  const [cableList, setCableList] = useState([])
  const [loading, setLoading] = useState(false)
  const[priceList, setPriceList] = useState([])
  const [cable, setCable] = useState('')
  const [id, setId] = useState('')
  const [show, setShow] = useState(false)
  const [email,setEmail] = useState('')
  const [amount, setAmount] = useState(0)
  const [pin, setPin] = useState('')
  const [iuc, setIuc] = useState('')
  const [name, setName] = useState('')
  const [status, setStatus] = useState(false)
  const [ref, setRef] = useState('')
  const initialConfig = { reference: null, email: email, amount: 0, publicKey:process.env.REACT_APP_PAYSTACK_KEY, };
  const [configP, setConfig] = useState(initialConfig);
  let initializePayment = usePaystackPayment(configP);
  const handleSelect2 = (event) => {
    const selectedItem = priceList.find(item => item.code === event.target.value);
    if (selectedItem) {
      setCable(selectedItem.code);
      setAmount(selectedItem.price);
    }
  };
  useEffect(() =>{
    if(iuc.length > 7){
      postService.dstvNameLookup(id,iuc).then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
            
           setName(response.data.data.name)
           }else{  
            // toast.error(response.data.description,)
           }
           
          },
          (error) => {
            
            }
      )
    }
    
  },[iuc,id])
useEffect(() =>{
    
  setLoading(true)
  getServices.getCable().then(
    (response) => {
        console.log(response.data)
        setLoading(false)
       if(response.data.status === '2000'){
        setCableList(response.data.data)
       
       }else{  
      
        toast.error(response.data.description,)
       }
       
      },
      (error) => {
        setLoading(false)
          return  toast.error('Login failed. Try again',)
        }
  )


},[])
const onSubmit = (e) =>{
  e.preventDefault()
  setLoading(true)
  const value = {
    "account_number": iuc,
    "amount": amount * 100,
    "beneficiary": iuc,
    "callback_url": "string",
    "channel": [
      "string"
    ],
    "datacode": "string",
    "email": email,
    "product": cable,
    "product_code": id,
    "service_type": 'cable'
  }
  postService.purchaseWithoutLogin(value).then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
         
          const reference = response.data.data.reference
              
          setConfig({
            ...configP, channel: [
              'card',
              'bank',
              'ussd',
              'qr',
              'mobile_money',
              'bank_transfer',
              'eft',
            ], email: email, reference: reference, amount: amount * 100,
          });
         }else{  
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Error. Try again',)
          }
    )
}
const getPricing = (name) =>{
 
  setLoading(true)
  postService.cablePriceList(name).then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
         
         setPriceList(response.data.data)
         }else{  
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Login failed. Try again',)
          }
    )
}
const onSuccess = (reference) => {
  // Implementation for whatever you want to do with reference and after success call.
  console.log( reference);
  console.log()
  setRef(reference.reference)
  setShow(true)
  // verifyPayment(reference.reference)
};
const handleClos = () => {
  setShow(false)
  verifyPayment(ref)
}

// you can call this function anything
const onClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  console.log('closed')
  window.location.reload()
}
const verifyPayment = (ref) =>{
  setLoading(true)
  getServices.verifyFundingLog(ref).then(
    (response) => {
     setLoading(false)
      console.log(response.data)
      window.location.reload()
    },
    (error) => {
      setLoading(false)
      window.location.reload()
    }
  )
}
useEffect(() => {
  console.log(configP)
  if (configP.reference === null || configP.email === undefined) return;
  initializePayment(onSuccess, onClose)
}, [configP])
  return (
    <Modal cancelbtn={cancelIcon} onSubmit={onSubmit} text='pay' title='Cable Payment' hide='hide' handleClose={closeIcon}>
    <Loading
      open={loading}
    />
     <div>
      <label className=' block font-medium mb-2'>Select Cable</label>
      <select value={id} onChange={(e) => {
       setId(e.target.value)
       getPricing(e.target.value)
      } } required className=' w-full border rounded p-3 mb-4'>
        <option value={''}>Select cable</option>
        {cableList.map((plan) =>{
          return  <option value={plan.serviceType} key={plan.serviceType}>{plan.name}</option>
        })}
       </select>
      </div>
      <CustomTextField
      value={iuc}
      onChange={setIuc}
       type='text' 
       label='IUC number' />
      <div className=' mt-2'>
      {name.length>0 &&
       <div className=' my-5'>
            <p>Name: {name}</p>
       </div>
       }
      <label className=' block font-medium mb-2'>Cable Plan</label>
      <select value={cable} onChange={handleSelect2} required className=' w-full border rounded p-3 mb-3'>
        <option value={''}>Select plan</option>
        {priceList.map((plan) =>{
          return  <option value={plan.code} key={plan.code}>{plan.name}</option>
        })}
       </select>
      </div>
      <CustomInput
       type='Amount' 
       image={Naira}
       disabled= {true}
       valuee={amount}
       
        placeholder='Amount' />
    
    <div className='my-5'></div>
        <CustomTextField
                    value={email}
                    required
                    name={'email'}
                    onChange={setEmail}
                     type='email' label='Email'/>  
     <div className='my-5'></div>
     
    </Modal>
  )
}

export default Cable
