import React from 'react'
import { BalanceCard } from '../../../Components/Unauthorized/BalanceCard'
import QuickAction from '../../../Components/Unauthorized/QuickAction'
import W from '../../../Assets/Images/w.svg'
import Button from '../../../Components/Input/Button'
import { Link } from 'react-router-dom'

function Home() {
  return (
    <div>
        <BalanceCard />
        <div className='grid grid-cols-2 w-full  mt-5 gap-6'>
        <QuickAction />
        <div className=' shadow-md w-full'>
            <div className=' bg-white p-5 w-full' >
            <h1 className='text-[21px] font-[500] mb-2'>Recent transactions</h1>
            <div className='  mt-[150px] flex-col'>
                <div >
                    <img src={W} className=' mb-3 mx-auto' alt='Transaction'/>
                    

                    <Link to={'/login'}>
                    <div className='flex justify-center'>
                    <Button
                        name={"Sign in to view transactions"}
                    />
                    </div>
                    </Link>
                   
                </div>
            </div>
            </div>
        </div>
       </div>
    </div>
  )
}

export default Home