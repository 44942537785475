import React, { useEffect, useState } from 'react'
import Container from './Container'
import { TransactionHistory } from './card'
import Sent from '../../Assets/Images/sent.svg'
import Receive from '../../Assets/Images/receive.svg'
import getServices from '../../Services/get-service'
import Loading from '../Input/Loading'

const TransactionCard = () => {
  const [trans, setTransaction] = useState([])
     const[loading, setLoading] = useState()
     useEffect(() =>{
        setLoading(true)
        getServices.getTransactionHistory().then(
            (res) =>{
                setTransaction(res.data.data.reverse());
                setLoading(false)
            }
        )
     },[])
   

  return (
    <Container tag="Recent Transactions">
      <Loading
        open={loading}
      />
        <TransactionHistory 
        list={trans?.slice(0,9)} 
        Sent={Sent}
        Receive={Receive}
        />
    </Container>
  )
}

export default TransactionCard