import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import editPencil from '../../Assets/Images/pencil-line.svg'
import CustomTextField from '../Input/input.jsx'
import { PrimaryBtn } from '../Button/button.jsx'
import Button from '../Input/Button.js'
import postService from '../../Services/post-service.js'
import { ToastContainer, toast } from 'react-toastify'
import Loading from '../Input/Loading.js'

const Security = () => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPass] = useState('')
  const [pin, setPin] = useState('') 
  const [transactionPin, setTransactionPin] = useState('')
  const [oldPin, setOldPin] = useState('')
  const [newPin, setNewPin] = useState('')
const [loading, setLoading] = useState(false)

  const handleChangePass = (e) =>{
       e.preventDefault()
       setLoading(true)
  postService.changePassword(pin,newPassword).then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
          toast.success(response.data.description)
          setTimeout(() => {
            window.location.reload()
          }, 2000);
         
         }else{  
        
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Error occured. Try again',)
          }
    )
  }

const handleChangePin = (e) =>{
       e.preventDefault()
       setLoading(true)
  postService.changePin(newPin,oldPin).then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
          toast.success(response.data.description)
          setTimeout(() => {
            window.location.reload()
          }, 2000);
         
         }else{  
        
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Error occured. Try again',)
          }
    )
  }
  return (
    <div>
    <Loading
      open={loading}
    />
    <ToastContainer/>
      <div className='bg-white p-2 rounded'>
        <div className='w-full flex justify-between items-center p-3 pb-6'>
          <div>
            <h1 className='font-[600] text-[20px]'>Password</h1>
            <p className='text-[16px] font-[400] mt-1'>Please enter your current password to change your password</p>
          </div>
          <Link className='text-primary flex items-center font-[600]'>
            Edit
            <img src={editPencil} alt="" className='ml-1' />
          </Link>
        </div>
        <hr />

        <form onSubmit={handleChangePass}>
          <div className='flex items-center p-3'>
            <h1 className='w-[30%]'>Current password</h1>

            <div className='flex w-[55%] justify-between'>
              <CustomTextField 
              type='password'
              required
              value={currentPassword}
              onChange={setCurrentPassword}
               label='Current password' />
            </div>
          </div>
          <hr />
          <div className='flex items-center p-3'>
            <h1 className='w-[30%]'>New password</h1>
            <div className='w-[55%]'>
              <CustomTextField 
              value={newPassword}
              onChange={setNewPass}
               type='password' label='New password' />
              <p className='text-[13px] font-[500] my-3'>Your new password must be more than 8 characters</p>
            </div>
          </div>
          <hr />
          <div className='flex items-center p-3'>
            <h1 className='w-[30%]'>Transaction Pin</h1>
            <div className='w-[55%]'>
              <CustomTextField
              onChange={setPin}
              value={pin}
               type='password' label='Pin' />
            </div>
          </div>
          <div className='flex justify-end items-center p-3'>
          {/* <div className='mr-3'>
            <PrimaryBtn variant="outlined" text='Cancel' />
          </div> */}

         <Button
          name={'Update password'}
         />
        </div>
        </form>
        <hr />


       
      </div>

      <div className='mt-5 bg-white p-2 rounded'>
        <div className='w-full flex justify-between items-center p-3 pb-6'>
          <div>
            <h1 className='font-[600] text-[20px]'>PIN</h1>
            <p className='text-[16px] font-[400] mt-1'>Please enter your current PIN to change your PIN</p>
          </div>
          <Link className='text-primary flex items-center font-[600]'>
            Edit
            <img src={editPencil} alt="" className='ml-1' />
          </Link>
        </div>
        <hr />

        <form onSubmit={handleChangePin}>
          <div className='flex items-center p-3'>
            <h1 className='w-[30%]'>Current PIN</h1>

            <div className='flex w-[55%] justify-between'>
              <CustomTextField
              value={oldPin}
              onChange={setOldPin}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
              type='password' 
              label='Current PIN' />
            </div>
          </div>
          <hr />
          <div className='flex items-center p-3'>
            <h1 className='w-[30%]'>New PIN</h1>
            <div className='w-[55%]'>
              <CustomTextField
              value={newPin}
              onChange={setNewPin}                                                                                                                                                                                                                                                                                                                                                                                           
               type='password'
                label='New PIN' />
              {/* <p className='text-[13px] font-[500] my-3'>Your new PIN must be more than 8 characters</p> */}
            </div>
          </div>
          <hr />
          {/* <div className='flex items-center p-3'>
            <h1 className='w-[30%]'>Confirm PIN</h1>
            <div className='w-[55%]'>
              <CustomTextField type='password' label='Confirm PIN' />
            </div>
          </div> */}
          <div className='flex justify-end items-center p-3'>
          {/* <div className='mr-3'>
            <PrimaryBtn variant="outlined" text='Cancel' />
          </div> */}

         <Button
          name={'Update pin'}
         />
        </div>
        </form>
        <hr />


        
      </div>
    </div>
  )
}

export default Security
