import React, { useEffect, useState } from 'react'
import Select from '../Input/select'
import Naira from '../../Assets/Images/Naira.png'
import Modal from '../Card/modalCont'
import CustomInput from '../Input/custom.jsx'
import CustomTextField from '../Input/input.jsx'
import CustomizedSwitches from '../Input/switch.jsx'
import cancelIcon from '../../Assets/Images/cancelIcon.svg'
import getServices from '../../Services/get-service.js'
import { toast } from 'react-toastify'
import Loading from '../Input/Loading.js'
import postService from '../../Services/post-service.js'

const Cable = ({closeIcon}) => {
  
  const [cableList, setCableList] = useState([])
  const [loading, setLoading] = useState(false)
  const[priceList, setPriceList] = useState([])
  const [cable, setCable] = useState('')
  const [id, setId] = useState('')
  const [amount, setAmount] = useState(0)
  const [pin, setPin] = useState('')
  const [iuc, setIuc] = useState('')
  const [name, setName] = useState('')
  const [status, setStatus] = useState(false)
  const handleSelect2 = (event) => {
    const selectedItem = priceList.find(item => item.code === event.target.value);
    if (selectedItem) {
      setCable(selectedItem.code);
      setAmount(selectedItem.price);
    }
  };
  useEffect(() =>{
    if(iuc.length > 7){
      postService.dstvNameLookup(id,iuc).then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
            
           setName(response.data.data.name)
           }else{  
            // toast.error(response.data.description,)
           }
           
          },
          (error) => {
            
            }
      )
    }
    
  },[iuc,id])
useEffect(() =>{
    
  setLoading(true)
  getServices.getCable().then(
    (response) => {
        console.log(response.data)
        setLoading(false)
       if(response.data.status === '2000'){
        setCableList(response.data.data)
       
       }else{  
      
        toast.error(response.data.description,)
       }
       
      },
      (error) => {
        setLoading(false)
          return  toast.error('Login failed. Try again',)
        }
  )


},[])
const onSubmit = (e) =>{
  e.preventDefault()
  setLoading(true)
  postService.purchaseCable(amount,pin,id,iuc,cable).then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
         
          toast.success(response.data.description)
          setTimeout(() => {
            window.location.reload()
          }, 2000);
         }else{  
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Login failed. Try again',)
          }
    )
}
const getPricing = (name) =>{
 
  setLoading(true)
  postService.cablePriceList(name).then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
         
         setPriceList(response.data.data)
         }else{  
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Login failed. Try again',)
          }
    )
}
  return (
    <Modal cancelbtn={cancelIcon} onSubmit={onSubmit} text='pay' title='Cable Payment' hide='hide' handleClose={closeIcon}>
    <Loading
      open={loading}
    />
     <div>
      <label className=' block font-medium mb-2'>Select Cable</label>
      <select value={id} onChange={(e) => {
       setId(e.target.value)
       getPricing(e.target.value)
      } } required className=' w-full border rounded p-3 mb-4'>
        <option value={''}>Select cable</option>
        {cableList.map((plan) =>{
          return  <option value={plan.serviceType} key={plan.serviceType}>{plan.name}</option>
        })}
       </select>
      </div>
      <CustomTextField
      value={iuc}
      onChange={setIuc}
       type='text' 
       label='IUC number' />
      <div className=' mt-2'>
      {name.length>0 &&
       <div className=' my-5'>
            <p>Name: {name}</p>
       </div>
       }
      <label className=' block font-medium mb-2'>Cable Plan</label>
      <select value={cable} onChange={handleSelect2} required className=' w-full border rounded p-3 mb-3'>
        <option value={''}>Select plan</option>
        {priceList.map((plan) =>{
          return  <option value={plan.code} key={plan.code}>{plan.name}</option>
        })}
       </select>
      </div>
      <CustomInput
       type='Amount' 
       image={Naira}
       disabled= {true}
       valuee={amount}
       
        placeholder='Amount' />
    
    <div className='my-5'></div>
        <CustomTextField
                    value={pin}
                    required
                    name={'pin'}
                    onChange={setPin}
                     type='password' label='PIN'/>  
     
      <div className='w-full my-[10px] p-2 flex justify-between items-center'>
        <p>
          Add to Beneficiary
        </p>
        <CustomizedSwitches value={status} onChange={setStatus} />
      </div>
    </Modal>
  )
}

export default Cable
