import React from 'react'
import BasicTabs from '../../Components/Tab/Tab'

const index = () => {
  return (
    
      <div className='px-5 '>
      <BasicTabs />
      </div>

  )
}

export default index