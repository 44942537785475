import axios from "axios";
import authHeader from "./AuthHeader";


const getAllBank = () => {
    return axios.get( "/api/get-banks");
}
const getUserBanks = () => {
    return axios.get( "/api/user-bankDetail", { headers: authHeader() });
}
const getTransactionHistory = () => {
    return axios.get( "/api/topupcliq-transaction-history", { headers: authHeader() });
}
const refreshToken = () => {
    return axios.get( "/api/topupcliq-refresh-token", { headers: authHeader() });
}
const verifyBankAccount = (accNum, bankCode) => {
    return axios.get( `/api/verify-accountNumber?accountNumber=${accNum}&bankCode=${bankCode}`, { headers: authHeader() });
}

const getWallerBalance = () =>{
    return axios.get( "/api/topupcliq-get-balance", { headers: authHeader() });
}
const getVirtualAccount = () =>{
    return axios.get( "/api/get-virtual-account", { headers: authHeader() });
}

const getDataPlan = (network) =>{
    return axios.get( `/api/topupcliq-data-plan?network=${network}`, { headers: authHeader() });
}

const getElectricity = () =>{
    return axios.get( `/bills/all-billers`, { headers: authHeader() });
}
const verifyFunding = (reference) =>{
    return axios.get( `/api/topupcliq-verify-wallet?deviceId=$deviceId&reference=${reference}`, { headers: authHeader() });
}
const smeDataList= () =>{
    return axios.get( `/api/sme-plan`, { headers: authHeader() });
}
const getCable = () =>{
    return axios.get( `/bills/cabletv-providers`, { headers: authHeader() });
}
const getRequiringData = () =>{
    return axios.get( `/api/saved-items-details`, { headers: authHeader() });
}
const verifyFundingLog = (reference) =>{
    return axios.get( `/api/webbapp-verify-wallet?businessName=topupbox&reference=${reference}`, { headers: authHeader() });
}


const getServices = {
    getAllBank,
    verifyBankAccount,
    getUserBanks,
    getTransactionHistory,
    refreshToken,
    getWallerBalance,
    getVirtualAccount,
    getDataPlan,
    getElectricity,
    verifyFunding,
    smeDataList,
    getCable,
    getRequiringData,
    verifyFundingLog
}

export default getServices;
