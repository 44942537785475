import React, { useEffect, useState } from 'react'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { format, parseISO } from 'date-fns';
import Loading from '../../Components/Input/Loading';
import getServices from '../../Services/get-service';
import Constant from '../../Constant';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2948CE',
    color: 'white',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Index = () => {
  const [transaction, setTransaction] = useState([])
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10);
   const [filterValue, setFilterValue] = useState('all')
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() =>{
    setLoading(true)
    getServices.getTransactionHistory().then(
        (response) => {
          
          setTransaction(response.data.data.reverse());
          console.log(response.data.data)
          setLoading(false)
        },
        (error) => {
          
        }
      )
}, [])

let filteredData =  filterValue === 'all'?
transaction
:
transaction.filter(item => item.transType  === filterValue);

  return (
    <div>
     <Loading
      open={loading}
    />
      <h1 className=' font-bold'>Transaction</h1>
     <div className=' mb-6 mt-2 grid grid-cols-3 gap-3'>
       <div>
       <p className=' mb-2'>Filter by Transaction type:</p>
        <select value={filterValue} 
        onChange={(e) =>setFilterValue(e.target.value)}
        className=' w-full p-3 border rounded-lg'>
         <option  value={'all'}>All</option>
          <option  value={'D'}>Debit</option>
          <option value={'C'}>Credit</option>
        </select>
       </div>
     </div>
    <Paper className=' ' sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{backgroundColor:'red', color: 'white',}}>
            <TableRow >
            <StyledTableCell>Transaction type</StyledTableCell>
            <StyledTableCell align="left">Date/Time</StyledTableCell>
            <StyledTableCell align="left">Amount</StyledTableCell>
          
            <StyledTableCell align="left">Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((tran) => (
                
            <TableRow
              key={tran.transactionDate}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
             <span className=' capitalize'>{tran.transType === 'D'?<span className=' text-custom-red'>Debit</span> :<span className=' text-custom-green'>Credit</span>}</span> 
              </TableCell>
              <TableCell align="left">{format(parseISO(tran.createdDate), 'MMM d yyyy hh:mm:ss a')}</TableCell>
              <TableCell align="left"> {tran.transType === 'D' ?<span className='text-custom-red'>- NGN {tran.amount}</span>:<span className='text-custom-green'>+ NGN {Constant.FormatNumber(tran.amount??0.0) }</span>}</TableCell>
              {/* <TableCell align="left">{tran.narration}</TableCell> */}
              <TableCell align="left">{tran.narration !== null ? tran.narration?.slice(0,45):tran.narration} </TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {filteredData.length === 0 && <p className=' text-center font-bold text-red-700 mt-5'>No transaction</p>}
    </Paper>
    </div>
  )
}

export default Index

