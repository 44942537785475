import React from 'react'
import Balance from '../Button/Balance';

import Send from '../../Assets/Images/send-2.svg'
import Fund from '../../Assets/Images/wallet-add.svg'
import ModalComponent from '../Modal/Pin';
import { Link } from 'react-router-dom';
const BalanceCard = () => {


 
   
 
    return (
        <>
            
       
        <div className='w-full rounded-lg bg-[#2948CE] py-9 px-4 text-white flex justify-between items-center'>
          
            <div>
                <p className='text-[14px]'>Log in, fund your wallet & enjoy more discounts!</p>

                <h1 className='text-[24px] font-[600] '>
                Don't have an account? <Link to={'/signup'} className=' underline'>SIGN UP</Link> 
                </h1>
            </div>

            <div className='flex'>
                <Balance Icon={Fund} text="Fund Wallet"  />

                <Balance Icon={Send} text="Send to User"  />
            </div>
               
          
        </div>
        </>
    );
}

const QuickActionCard = ({ card = [] }) => {
    return (
        <div className='grid grid-cols-2 gap-2 items-center'>
            {card.map((cards, i) => (
                <>
                    <div key={i} onClick={cards.handleOpen} className='border border-primary-[1px] rounded-lg w-full flex flex-col justify-center items-center p-[70px] cursor-pointer'>
                        <img src={cards.icon} alt='Quick Action' />
                        <p className='text-nowrap mt-2 font-[500] text-[18px]'>{cards.actionName}</p>
                    </div>
                    <ModalComponent open={cards.open} handleClose={cards.handleClose}>
                        {cards.children}
                    </ModalComponent>
                </>
            ))}



        </div>
    )
}




export { BalanceCard, QuickActionCard};