import React, { useEffect } from 'react'
import { useState } from 'react';
import Balance from '../Button/Balance';

import Send from '../../Assets/Images/send-2.svg'
import Fund from '../../Assets/Images/wallet-add.svg'

import cancelIcon from '../../Assets/Images/cancelIcon.svg'
import Naira from '../../Assets/Images/Naira.png'
import CustomInput from '../Input/custom.jsx'
import CustomTextField from '../Input/input.jsx'
import CustomizedSwitches from '../Input/switch.jsx'
import ModalComponent from '../Modal/Pin';
import ModalCont from './modalCont';
import getServices from '../../Services/get-service.js';
import Constant from '../../Constant.js';

import { format, parseISO } from 'date-fns';
import FundWallet from './FundWallet.js';
import postService from '../../Services/post-service.js';
import { toast } from 'react-toastify';
import Loading from '../Input/Loading.js';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
const BalanceCard = () => {
    const [open, setOpen] = useState(false);
    const [showBal, setShowBal] = useState(false)
    const [amount, setAmount] = useState('0.0')
    const [amount1, setAmount1] = useState('')
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [on, setOn] = useState(false);
    const onOpen = () => setOn(true);
    const Close = () => setOn(false);
    const [email,setEmail] = useState('')
    const [des, setDes] = useState('')
    const [pin, setPin] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() =>{
        getServices.getWallerBalance().then(
            (response) => {
                console.log(response.data)
                setOpen(false)
               if(response.data.status === '2000'){
                    setAmount(response.data.data.accountBalance)
               }else{
               
               }
               
              },
              (error) => {
               
                }
        )

    },[])
   
    const onSubmit = (e) =>{
            e.preventDefault()
            setLoading(true)
            postService.sendMoney(amount,pin,email,des).then(
                (response) => {
                    console.log(response.data)
                    setLoading(false)
                   if(response.data.status === '2000'){
                    toast.success(response.data.description)
                    setTimeout(() => {
                        window.location.reload()
                      }, 2000);
                   }else{  
                  
                    toast.error(response.data.description,)
                   }
                   
                  },
                  (error) => {
                    setLoading(false)
                      return  toast.error('Login failed. Try again',)
                    }
              )
    }

    return (
        <>
            
       
        <div className='w-full rounded-lg bg-[#2948CE] py-9 px-4 text-white flex justify-between items-center'>
          
            <div>
                <p className='text-[14px]'>Wallet Balance,</p>

                <h1 className='text-[24px] font-[600] '>
                    NGN {showBal? Constant.FormatNumber(amount): '***'} {showBal? <RemoveRedEyeIcon onClick={() => setShowBal(!showBal)}/>:<VisibilityOffIcon onClick={() => setShowBal(!showBal)}/>}
                </h1>
            </div>

            <div className='flex'>
                <Balance Icon={Fund} text="Fund Wallet" handleOpen={handleOpen} />

                <Balance Icon={Send} text="Send to User" handleOpen={onOpen} />
            </div>
                <FundWallet
                  open={open} handleClose={handleClose}
                />

             <ModalComponent open={on} handleClose={Close}>
             <Loading open={loading}/>
                <ModalCont hide='hide' handleClose={Close} onSubmit={onSubmit} title='Send to user' cancelbtn={cancelIcon} text='Pay'>
                    <CustomTextField 
                    value={email}
                    onChange={setEmail}
                    type='text' label={`Receiver's email address`} />
                    <CustomInput 
                    value={amount1}
                    onChange={setAmount1}
                    type='Amount'
                     image={Naira} />
                    <div className='mt-[23px]'></div>
                    <CustomTextField 
                    value={des}
                    onChange={setDes}
                    type='text' label={`Description`} />
                    <div className=' my-5'>

                    </div>
                    <CustomTextField
                    value={pin}
                    onChange={setPin}
                     type='password' label='PIN'/>
                    <div className='w-full my-[10px] p-2 flex justify-between items-center'>
                        <p>
                            Add to Beneficiary
                        </p>
                        <CustomizedSwitches />
                    </div>
                </ModalCont>
            </ModalComponent>
        </div>
        </>
    );
}

const QuickActionCard = ({ card = [] }) => {
    return (
        <div className='grid grid-cols-2 gap-2 items-center'>
            {card.map((cards, i) => (
                <>
                    <div key={i} onClick={cards.handleOpen} className='border border-primary-[1px] rounded-lg w-full flex flex-col justify-center items-center p-[70px] cursor-pointer'>
                        <img src={cards.icon} alt='Quick Action' />
                        <p className='text-nowrap mt-2 font-[500] text-[18px]'>{cards.actionName}</p>
                    </div>
                    <ModalComponent open={cards.open} handleClose={cards.handleClose}>
                        {cards.children}
                    </ModalComponent>
                </>
            ))}



        </div>
    )
}


const TransactionHistory = ({ list = [], Sent, Receive }) => {
     
    return (
        <div className='h-[475px] overflow-scroll'>
            <ul>
                {list.map((lists, index) => (
                    <li key={index} className='flex justify-between items-center mt-4'>
                        <div className='flex'>
                            <img src={lists.transType === 'D'? Sent:Receive} alt="icon" className='mr-2' />

                            <div >
                                <h1 className='font-[600]'>{lists.transType === 'D'?<span className=' text-custom-red'>Debit</span> :<span className=' text-custom-green'>Credit</span>}</h1>
                                <p className='font-[400]'>{list.narration !== null ? lists.narration?.slice(0,15):lists.narration}</p>
                            </div>
                        </div>

                        <div>
                            <h1 id={lists.color}>{lists.transType === 'D' ?<span className='text-custom-red'>- NGN {lists.amount}</span>:<span className='text-custom-green'>+ NGN {Constant.FormatNumber(lists.amount??0.0) }</span>}</h1>
                            <p>{format(parseISO(lists.createdDate), 'MMM d yyyy hh:mm:ss a')}</p>
                        </div>
                    </li>
                ))}
            </ul>
            {list.length === 0 && <p className=' text-center font-bold text-red-700 mt-5'>No transaction</p>}

        </div>
    )
}

export { BalanceCard, QuickActionCard, TransactionHistory };