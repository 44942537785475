import  React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Logo from '../../Assets/Images/logo.svg'
import { Link, NavLink, useLocation} from 'react-router-dom';
import getServices from '../../Services/get-service';
import cookie from 'react-cookies';
import Beneficiaries from '../../Assets/Images/Beneficiaries'
import Dashboardd from '../../Assets/Images/Dashboard'
import Transaction from '../../Assets/Images/Transaction'
import Profile from '../../Assets/Images/Profile'
import { toast, ToastContainer } from 'react-toastify';
import Loog from '../../Assets/Images/loog.svg'
import 'react-toastify/dist/ReactToastify.css';
import Routess from './Routes';


const drawerWidth = 240;


function Dashboard(props) {
    const { window } = props;
    const details = localStorage.getItem('userdetails')
    const user =  JSON.parse(details)
    const location = useLocation();
    console.log(location)
    const [open, setOpen] = useState(false)
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

 

     
  
  useEffect(() =>{
    let interval= "";
    const check = ()=>{
      interval = setInterval(e=>{
      const token = cookie.load("token");
  
      if (!token) {
        clearInterval(interval);
        window.location.href = '/';
        this.props.navigate(0);
        return;
      }
    },10000);
    }
      check();
      getServices.refreshToken().then(
        (response) => {
            console.log(response.data)
           
           if(response.data.status === '2000'){
            cookie.save("token", response.data.data.Token, {
              path: "/",
              expires: new Date(Date.now() + 60 * 10 * 1000),
            });
            

           }else{
            
           
           }
           
          },
          (error) => {
            
            }
      )
      
  }, [])


  useEffect(() =>{
    console.log("heeloo")
  getServices.refreshToken().then(
    (response) => {
        console.log(response.data)
       
       if(response.data.status === '2000'){
        cookie.save("token", response.data.data.Token, {
          path: "/",
          expires: new Date(Date.now() + 60 * 10 * 1000),
        });
        

       }else{
        toast('Session Expired', {
          onClose: () => {
            window.location.href = '/'
          },
          autoClose: 5000
        })
       
       }
       
      },
      (error) => {
        toast('Session Expired', {
          onClose: () => {
            window.location.href = '/'
          },
          autoClose: 5000
        })
        }
  )
      })

 

  const [sidebar, setSidebar] = useState([
    {
        name: 'Dashboard',
        icon: (
            <Dashboardd/>
        ),
        link: '/dashboard',
        id: 'dashbord',
    },
    {
        name: 'Transactions',
        icon: (
          <Transaction/>
        ),
        link: '/transactions',
        id: 'Transaction',
    },
   
    {
        name: 'Beneficiaries',
        icon: (
          <Beneficiaries/>
        ),
        link: '/beneficiaries',
        id: 'bene',
    },
   
    {
        name: 'Settings',
        icon: (
          <Profile/>
        ),
        link: '/settings',
        id: '/settings',
    },
  
   
  ])
  const drawer = (
    <div>
     <div className=' pt-2 border-b border-b-gray-300 mb-2 pb-4 bg-[#F8FAFC]  '>
        <img src={Logo}  alt='logo' className='  mx-auto'/>
        
          </div>
     
      <List>
          {sidebar.map((text, index) => (
            <NavLink
             className={({isActive}) =>{
                            return( ' block  p-3 mb-3 pl-6 ' + (
                              !isActive ? ' block ' : ' flex mt-[5px] text-[#2948CE] bg-secondary/[0.2] items-center p-3 rounded sidebar'
                            ))
                        }}  
                      to={text.link}>
                      <div className=' flex'>
                      {text.icon}
                      <span className=' pl-3'>{text.name}</span>
                      </div>
             
           
            </NavLink>
           
            
          ))}
          
        </List>
        <p className=' mx-5 flex '><Link to='/logout'><img src={Loog} alt='alt' className='pl-1 inline-block '/><span className=' pl-3 text-red-600 inline-block'>Logout</span></Link></p>
      <div className=' mt-40'>
         <p className=' text-primary-color6 text-center'> ©️ 2024 - Topupbox v1 </p>
      </div>
    </div>
  );
  const convertToTitleCase = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };


  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div>
    <ToastContainer/>
    
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
       
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
         backgroundColor: '#F8FAFC',
        }}
      >
      <div className=' flex justify-between'>
      <div className=' flex justify-between'>
      <Toolbar>
      <IconButton
            
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
         
                
       
        </Toolbar>
        <div className=' flex flex-col justify-center'>
          <p className=' text-black md:text-[24px] capitalize font-[400]'>Welcome  
          {/* {convertToTitleCase(user.firstName)}  */}
          </p>
         </div>
      </div>
     
            <div className=' md:flex hidden justify-center gap-2'>
            <div className=' flex flex-col justify-center'>
            
            </div>
          
            <div className='pt-5 relative'>
           
            <div className='absolute top-4 right-1'>
            <Profile/>
            </div>
            </div>
           
            <div className=' mt-3 flex gap-2 mr-3 relative cursor-pointer' onClick={() => setOpen(!open)}>
            <div>
            {/* <p className=' px-3 py-2 rounded-[44px] bg-back-color2'> {Array.from(user.lastName)[0]}</p>  */}
            </div>
           
            <div>
            <p className=' text-black inline-block mt-1 font-bold text-[16px] mr-4'>{convertToTitleCase(user.firstName)}  {convertToTitleCase(user.lastName)} </p>
            </div>
          
               
            </div>
                    </div>
                 </div>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >
          {drawer}
          

        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth,  backgroundColor: 'white' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
      <div className=' w-full  h-screen' >
            <Routess/>  
      </div>
        
      </Box>
    </Box>
    </div>
  )
}
Dashboard.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

export default Dashboard