import React, { useEffect, useState } from 'react'
import Select from '../../Components/Input/select'
import BeneficiaryCard from '../../Components/Card/Beneficiary'
import getServices from '../../Services/get-service'
import { toast } from 'react-toastify'
import Loading from '../../Components/Input/Loading'
// import AddIcon from '@mui/icons-material/Add'

const Index = () => {
 
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [airtime, setAirtime] = useState([])
  const [smeData, setSmeData] = useState([])

  useEffect(() =>{
 
      setLoading(true)
      getServices.getRequiringData().then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
           setData(response.data.dataList)
           setAirtime(response.data.airtimeDataList)
           setSmeData(response.data.smeDataList)
           }else{  
          
            toast.error(response.data.description,)
           }
           
          },
          (error) => {
            setLoading(false)
              return  toast.error('Login failed. Try again',)
            }
      )
    
    
   },[])
   
  return (
    <>
    <Loading
      open={loading}
    />
        <div className='flex w-full justify-between items-center p-3 bg-white'>
        <Select selectText='Beneficiary type' width='400px' />
        {/* <PrimaryBtn text='Add beneficiary' variant='contained' size='large' /> */}
        </div>

        <div className='w-full p-3 grid grid-cols-3 gap-4 mt-6'>
          <BeneficiaryCard Card={airtime} type={'Airtime'}/>
        </div>
        <div className='w-full p-3 grid grid-cols-3 gap-4 mt-6'>
          <BeneficiaryCard Card={data} type={'Data'}/>
        </div>
        <div className='w-full p-3 grid grid-cols-3 gap-4 mt-6'>
          <BeneficiaryCard Card={smeData} type={'SME Data'}/>
        </div>
        {data.length ===0 && smeData.length ===0 && airtime.length ===0 && <p className=' text-center text-2xl text-red-500'></p>}
    </>
  )
}

export default Index