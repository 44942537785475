import React, { useEffect, useState } from 'react'
import Modal from '../../Card/modalCont'
import cancelIcon from '../../../Assets/Images/cancelIcon.svg'
import Select from '../../Input/select'
import CustomInput from '../../Input/custom.jsx'
import Code from '../../../Assets/Images/Code.png'
import Naira from '../../../Assets/Images/Naira.png'
import CustomizedSwitches from '../../Input/switch.jsx'
import postService from '../../../Services/post-service.js'
import { toast } from 'react-toastify'
import CustomTextField from '../../Input/input.jsx'
import getServices from '../../../Services/get-service.js'
import Loading from '../../Input/Loading.js'

import { usePaystackPayment } from 'react-paystack';
const Data = ({closeIcon}) => {
  const [provide,setProvide] = useState('')
  const [provide2,setProvide2] = useState('')
  const [phone,setPhone] = useState('')
  const [amount, setAmount] = useState(0)
  const [pin, setPin] = useState('')
  const[email, setEmail] =useState('')
  const [status, setStatus] = useState(false)
  const [dataPlan, setDataPlan] = useState([])
  const [dataPlan2, setDataPlan2] = useState([])
  const [id, setId] = useState('')
  const [id2, setId2] = useState('')
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(true)
  const [ref, setRef] = useState('')
  const initialConfig = { reference: null, email: email, amount: 0, publicKey:process.env.REACT_APP_PAYSTACK_KEY, };
  const [configP, setConfig] = useState(initialConfig);
  let initializePayment = usePaystackPayment(configP);
  const selectProviders = [
    {
      value: "MTN",
      label: "MTN",
    },
    {
      value: "Airtel",
      label: "Airtel",
    },
   
    {
      value: "Glo",
      label: "Glo",
    },
    {
      value: "9mobile",
      label: "9mobile",
    },
  ];
  const selectProviders2 = [
    {
      value: "MTN",
      label: "MTN",
    },
    {
      value: "AIRTEL",
      label: "Airtel",
    },
   
    {
      value: "GLO",
      label: "Glo",
    },
    {
      value: "ETISALAT",
      label: "9mobile",
    },
  ];
  
 useEffect(() =>{
  if(provide !== ''){
    setLoading(true)
    getServices.getDataPlan(provide).then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
         setDataPlan(response.data.data.data)
         
         }else{  
        
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Login failed. Try again',)
          }
    )
  }
  
 },[provide])
  
 useEffect(() =>{
  
    setLoading(true)
    getServices.smeDataList().then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
         setDataPlan2(response.data.data)
         
         }else{  
        
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Eee failed. Try again',)
          }
    )
  
  
 },[])
 const [plans, setPlans] = useState([]);


  const handleNetworkChange = (event) => {
    const selectedNetwork = selectProviders2.find(item => item.value === event.target.value)
    const network = event.target.value;
    const v = selectedNetwork.value;
    if (selectedNetwork) {
    setProvide2(network);
    setPlans(dataPlan2[(v)] || []);
    }
  };
 const onSubmit = (e) =>{
  e.preventDefault()
  setLoading(true)
  const value = {
    "amount": amount * 100,
    "email": email,
  "channel": [
    "string"
  ],
  "beneficiary": phone,
  "network": provide,
  "planId": id,
  "product": 'data'
  }
  postService.purchaseWithoutLogin(value).then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
          const reference = response.data.data.reference
              
          setConfig({
            ...configP, channel: [
              'card',
              'bank',
              'ussd',
              'qr',
              'mobile_money',
              'bank_transfer',
              'eft',
            ], email: email, reference: reference, amount: amount * 100,
          });
         
         }else{  
        
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Error. Try again',)
          }
    )
}
const onSubmit2 = (e) =>{
  e.preventDefault()
  setLoading(true)
  const value = {
    "amount": amount * 100,
    "email": email,
  "channel": [
    "string"
  ],
  "beneficiary": phone,
  "network": provide,
  "planId": id2,
  "product": 'sme'
  }
  postService.purchaseWithoutLogin(value).then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
          const reference = response.data.data.reference
              
            setConfig({
              ...configP, channel: [
                'card',
                'bank',
                'ussd',
                'qr',
                'mobile_money',
                'bank_transfer',
                'eft',
              ], email: email, reference: reference, amount: amount * 100,
            });
         
         }else{  
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Error. Try again',)
          }
    )
}
const handleSelect = (event) => {
  const selectedItem = dataPlan.find(item => item.id === parseInt(event.target.value));
  if (selectedItem) {
    setId(selectedItem.id);
    setAmount(selectedItem.price);
  }
};
const handleSelect2 = (event) => {
  const selectedItem = plans.find(item => item.planId === event.target.value);
  if (selectedItem) {
    setId2(selectedItem.planId);
    setAmount(selectedItem.planPrice);
  }
};
const onSuccess = (reference) => {
  // Implementation for whatever you want to do with reference and after success call.
  console.log( reference);
  console.log()
  setRef(reference.reference)
  setShow(true)
  // verifyPayment(reference.reference)
};
const handleClos = () => {
  setShow(false)
  verifyPayment(ref)
}

// you can call this function anything
const onClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  console.log('closed')
  window.location.reload()
}
const verifyPayment = (ref) =>{
  setLoading(true)
  getServices.verifyFundingLog(ref).then(
    (response) => {
     setLoading(false)
      console.log(response.data)
      window.location.reload()
    },
    (error) => {
      setLoading(false)
      window.location.reload()
    }
  )
}
useEffect(() => {
  console.log(configP)
  if (configP.reference === null || configP.email === undefined) return;
  initializePayment(onSuccess, onClose)
}, [configP])
  return (
    <Modal cancelbtn={cancelIcon} onSubmit={ show?                                                                                                                                                                                                                                                                                                                                                                                           
      onSubmit:onSubmit2}  text='pay' title='Buy Data' hide='hide' handleClose={closeIcon}>
    <Loading
      open={loading}
    />
    <div className=' my-4 flex gap-5 '>
      <div onClick={() =>setShow(true)} className= {show?' text-center  w-full border p-2.5 rounded-md bg-primary':' w-full border p-2.5 rounded-md text-center'}>
      <p className={show?' text-center text-white':' text-center'} > Data bundle</p>
      </div>
      <div onClick={() =>setShow(false)} className= {!show?' text-center  w-full border p-2.5 rounded-md bg-primary':' w-full border p-2.5 rounded-md text-center'}>
      <p className={!show?' text-center text-white':' text-center'} > Sme Data</p>
        
      </div>
    </div>
    {show && 
      <div>
      <Select 
      value={provide}
      onChange={setProvide}
      selectProviders={selectProviders} selectText='Select network provider' width='100%' />
      <br />
      {provide !== '' &&
      <div>
      <label className=' block font-medium mb-2'>Select Data plan</label>
      <select value={id} onChange={handleSelect} required className=' w-full border rounded p-3 mb-4'>
        <option value={''}>Select data plan</option>
        {dataPlan.map((plan) =>{
          return  <option value={plan.id} key={plan.id}>{plan.description}</option>
        })}
       </select>
      </div>
       
      }
    
      <CustomInput
      value={phone}
      onChange={setPhone}
       type='Phone number' 
       image={Code} 
       placeholder='Phone number' />
      <CustomInput 
      value={amount}
      onChange={setAmount}
      disabled= {true}
      type='Amount' 
      image={Naira} 
      placeholder='Amount' />
     
     <div className='my-5'></div>
        <CustomTextField
                    value={email}
                    onChange={setEmail}
                     type='email' label='Email'/>
     <div className='my-5'></div>
      </div>
    }
    {!show &&
    <div>
    <div>
      <label className=' block font-medium mb-2'>Select network provider</label>
      <select value={provide2}  onChange={handleNetworkChange} required className=' w-full border rounded p-3 '>
        <option value={''}>Select data plan</option>
        {selectProviders2.map((plan) =>{
          return  <option value={plan.value} key={plan.value}>{plan.label}</option>
        })}
       </select>
      </div>
    
      <br />
      {provide2 !== '' &&
      <div>
      <label className=' block font-medium mb-2'>Select Data plan</label>
      <select value={id2} onChange={handleSelect2} required className=' w-full border rounded p-3 mb-4'>
        <option value={''}>Select data plan</option>
        {plans.map((plan) =>{
          return  <option value={plan.planId} key={plan.planId}>{plan.planName}</option>
        })}
       </select>
      </div>
       
      }

      <CustomInput
      value={phone}
      onChange={setPhone}
       type='Phone number' 
       image={Code} 
       placeholder='Phone number' />
      <CustomInput 
      value={amount}
      onChange={setAmount}
      disabled= {true}
      type='Amount' 
      image={Naira} 
      placeholder='Amount' />
     
     <div className='my-5'></div>
     <div className='my-5'></div>
        <CustomTextField
                    value={email}
                    onChange={setEmail}
                     type='email' label='Email'/>
     <div className='my-5'></div>
    </div>
    }
    
      
    </Modal>
  )
}

export default Data
