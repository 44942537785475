import React from 'react'

const Logout = () => {
    return (
        <div className="icon-container">
           <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.16667 9.16681H10.8333V10.8335H4.16667V13.3335L0 10.0001L4.16667 6.66681V9.16681ZM3.33273 15.0001H5.59029C6.76552 16.0374 8.30926 16.6668 10 16.6668C13.6819 16.6668 16.6667 13.6821 16.6667 10.0001C16.6667 6.31824 13.6819 3.33348 10 3.33348C8.30926 3.33348 6.76552 3.96287 5.59029 5.00014H3.33273C4.85307 2.97607 7.27363 1.66681 10 1.66681C14.6023 1.66681 18.3333 5.39777 18.3333 10.0001C18.3333 14.6025 14.6023 18.3335 10 18.3335C7.27363 18.3335 4.85307 17.0242 3.33273 15.0001Z" fill="#FF4040" />
        </svg> 
        </div>
        
    )
}

export default Logout