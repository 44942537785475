import React, { useEffect, useState } from 'react'
import Modal from '../../Card/modalCont'
import cancelIcon from '../../../Assets/Images/cancelIcon.svg'
import Select from '../../Input/select'
import CustomInput from '../../Input/custom.jsx'
import Code from '../../../Assets/Images/Code.png'
import Naira from '../../../Assets/Images/Naira.png'
import CustomizedSwitches from '../../Input/switch.jsx'
import CustomTextField from '../../Input/input.jsx'
import postService from '../../../Services/post-service.js'
import { toast } from 'react-toastify'
import Loading from '../../Input/Loading.js'
import { usePaystackPayment } from 'react-paystack';
import getServices from '../../../Services/get-service.js'

const Airtime = ({closeIcon}) => {
  const [provide,setProvide] = useState('')
  const [phone,setPhone] = useState('')
  const [email,setEmail] = useState('')
  const [amount, setAmount] = useState(0)
  const [show, setShow] = useState(false)
 
  const [loading, setLoading] = useState(false)
  const [ref, setRef] = useState('')
  const initialConfig = { reference: null, email: email, amount: 0, publicKey:process.env.REACT_APP_PAYSTACK_KEY, };
  const [configP, setConfig] = useState(initialConfig);
  let initializePayment = usePaystackPayment(configP);

  const selectProviders = [
    {
      value: "MTN",
      label: "MTN",
    },
    {
      value: "Airtel",
      label: "Airtel",
    },
   
    {
      value: "Glo",
      label: "Glo",
    },
    {
      value: "9mobile",
      label: "9mobile",
    },
  ];
  
  const onSubmit = (e) =>{
    e.preventDefault()
    setLoading(true)
    const value = {
      "amount": amount * 100,
      "email": email,
    "channel": [
      "string"
    ],
    "beneficiary": phone,
    "network": provide,
    "product": 'airtime'
    }
    postService.purchaseWithoutLogin(value).then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
            toast.success(response.data.description)
            const reference = response.data.data.reference
              
            setConfig({
              ...configP, channel: [
                'card',
                'bank',
                'ussd',
                'qr',
                'mobile_money',
                'bank_transfer',
                'eft',
              ], email: email, reference: reference, amount: amount * 100,
            });
           
           }else{  
          
            toast.error(response.data.description,)
           }
           
          },
          (error) => {
            setLoading(false)
              return  toast.error('Error. Try again',)
            }
      )
}
const onSuccess = (reference) => {
  // Implementation for whatever you want to do with reference and after success call.
  console.log( reference);
  console.log()
  setRef(reference.reference)
  setShow(true)
  // verifyPayment(reference.reference)
};
const handleClos = () => {
  setShow(false)
  verifyPayment(ref)
}

// you can call this function anything
const onClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  console.log('closed')
  window.location.reload()
}
const verifyPayment = (ref) =>{
  setLoading(true)
  getServices.verifyFundingLog(ref).then(
    (response) => {
     setLoading(false)
      console.log(response.data)
      window.location.reload()
    },
    (error) => {
      setLoading(false)
      window.location.reload()
    }
  )
}
useEffect(() => {
  console.log(configP)
  if (configP.reference === null || configP.email === undefined) return;
  initializePayment(onSuccess, onClose)
}, [configP])
  return (
    <Modal cancelbtn={cancelIcon}  text='pay' onSubmit={onSubmit} title='Buy Airtime' hide='hide' handleClose={closeIcon}>
    <Loading
      open={loading}
    />
      <Select 
      onChange={setProvide}
      value={provide}
      selectProviders={selectProviders} selectText='Select network provider' width='100%'/>
      <div>
      {/* <select
      value={provide}
      onChange={(e) =>setProvide(e.target.value)}
      >
        {selectProviders.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select> */}
      </div>
      <CustomInput type='Phone number'
      value={phone}
      onChange={setPhone}
       image={Code} />
      <CustomInput
        value={amount}
        onChange={setAmount}
       type='Amount' image={Naira} />
       <div className='my-5'></div>
        <CustomTextField
                    value={email}
                    required
                    onChange={setEmail}
                     type='email' label='Email'/>
     
     <div className='my-5'></div>
    </Modal>
  )
}

export default Airtime
