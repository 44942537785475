import React, { useEffect, useState } from 'react'
import Modal from '../Card/modalCont'
import cancelIcon from '../../Assets/Images/cancelIcon.svg'
import Select from '../Input/select'
import CustomInput from '../Input/custom.jsx'
import Code from '../../Assets/Images/Code.png'
import Naira from '../../Assets/Images/Naira.png'
import CustomizedSwitches from '../Input/switch.jsx'
import postService from '../../Services/post-service.js'
import { toast } from 'react-toastify'
import CustomTextField from '../Input/input.jsx'
import getServices from '../../Services/get-service.js'
import Loading from '../Input/Loading.js'


const Data = ({closeIcon}) => {
  const [provide,setProvide] = useState('')
  const [provide2,setProvide2] = useState('')
  const [phone,setPhone] = useState('')
  const [amount, setAmount] = useState(0)
  const [pin, setPin] = useState('')
  const [status, setStatus] = useState(false)
  const [dataPlan, setDataPlan] = useState([])
  const [dataPlan2, setDataPlan2] = useState([])
  const [id, setId] = useState('')
  const [id2, setId2] = useState('')
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(true)
  const selectProviders = [
    {
      value: "MTN",
      label: "MTN",
    },
    {
      value: "Airtel",
      label: "Airtel",
    },
   
    {
      value: "Glo",
      label: "Glo",
    },
    {
      value: "9mobile",
      label: "9mobile",
    },
  ];
  const selectProviders2 = [
    {
      value: "MTN",
      label: "MTN",
    },
    {
      value: "AIRTEL",
      label: "Airtel",
    },
   
    {
      value: "GLO",
      label: "Glo",
    },
    {
      value: "ETISALAT",
      label: "9mobile",
    },
  ];
  
 useEffect(() =>{
  if(provide !== ''){
    setLoading(true)
    getServices.getDataPlan(provide).then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
         setDataPlan(response.data.data.data)
         
         }else{  
        
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Login failed. Try again',)
          }
    )
  }
  
 },[provide])
  
 useEffect(() =>{
  
    setLoading(true)
    getServices.smeDataList().then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
         setDataPlan2(response.data.data)
         
         }else{  
        
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Eee failed. Try again',)
          }
    )
  
  
 },[])
 const [plans, setPlans] = useState([]);


  const handleNetworkChange = (event) => {
    const selectedNetwork = selectProviders2.find(item => item.value === event.target.value)
    const network = event.target.value;
    const v = selectedNetwork.value;
    if (selectedNetwork) {
    setProvide2(network);
    setPlans(dataPlan2[(v)] || []);
    }
  };
 const onSubmit = (e) =>{
  e.preventDefault()
  setLoading(true)
  postService.buyData(amount,pin,phone,provide,id,status).then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
          toast.success(response.data.description)
          setTimeout(() => {
            window.location.reload()
          }, 2000);
         
         }else{  
        
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Login failed. Try again',)
          }
    )
}
const onSubmit2 = (e) =>{
  e.preventDefault()
  setLoading(true)
  postService.purchaseSmeData(amount,pin,phone,id2,status).then(
      (response) => {
          console.log(response.data)
          setLoading(false)
         if(response.data.status === '2000'){
          toast.success(response.data.description)
          setTimeout(() => {
            window.location.reload()
          }, 2000);
         
         }else{  
          toast.error(response.data.description,)
         }
         
        },
        (error) => {
          setLoading(false)
            return  toast.error('Login failed. Try again',)
          }
    )
}
const handleSelect = (event) => {
  const selectedItem = dataPlan.find(item => item.id === parseInt(event.target.value));
  if (selectedItem) {
    setId(selectedItem.id);
    setAmount(selectedItem.price);
  }
};
const handleSelect2 = (event) => {
  const selectedItem = plans.find(item => item.planId === event.target.value);
  if (selectedItem) {
    setId2(selectedItem.planId);
    setAmount(selectedItem.planPrice);
  }
};
  return (
    <Modal cancelbtn={cancelIcon} onSubmit={ show?                                                                                                                                                                                                                                                                                                                                                                                           
      onSubmit:onSubmit2}  text='pay' title='Buy Data' hide='hide' handleClose={closeIcon}>
    <Loading
      open={loading}
    />
    <div className=' my-4 flex gap-5 '>
      <div onClick={() =>setShow(true)} className= {show?' text-center  w-full border p-2.5 rounded-md bg-primary':' w-full border p-2.5 rounded-md text-center'}>
      <p className={show?' text-center text-white':' text-center'} > Data bundle</p>
      </div>
      <div onClick={() =>setShow(false)} className= {!show?' text-center  w-full border p-2.5 rounded-md bg-primary':' w-full border p-2.5 rounded-md text-center'}>
      <p className={!show?' text-center text-white':' text-center'} > Sme Data</p>
        
      </div>
    </div>
    {show && 
      <div>
      <Select 
      value={provide}
      onChange={setProvide}
      selectProviders={selectProviders} selectText='Select network provider' width='100%' />
      <br />
      {provide !== '' &&
      <div>
      <label className=' block font-medium mb-2'>Select Data plan</label>
      <select value={id} onChange={handleSelect} required className=' w-full border rounded p-3 mb-4'>
        <option value={''}>Select data plan</option>
        {dataPlan.map((plan) =>{
          return  <option value={plan.id} key={plan.id}>{plan.description}</option>
        })}
       </select>
      </div>
       
      }
    
      <CustomInput
      value={phone}
      onChange={setPhone}
       type='Phone number' 
       image={Code} 
       placeholder='Phone number' />
      <CustomInput 
      value={amount}
      onChange={setAmount}
      disabled= {true}
      type='Amount' 
      image={Naira} 
      placeholder='Amount' />
     
     <div className='my-5'></div>
        <CustomTextField
                    value={pin}
                    onChange={setPin}
                     type='password' label='PIN'/>
      <div className='w-full my-[10px] p-2 flex justify-between items-center'>
        <p>
          Add to Beneficiary
        </p>
        <CustomizedSwitches value={status} onChange={setStatus} />
      </div>
      </div>
    }
    {!show &&
    <div>
    <div>
      <label className=' block font-medium mb-2'>Select network provider</label>
      <select value={provide2}  onChange={handleNetworkChange} required className=' w-full border rounded p-3 '>
        <option value={''}>Select data plan</option>
        {selectProviders2.map((plan) =>{
          return  <option value={plan.value} key={plan.value}>{plan.label}</option>
        })}
       </select>
      </div>
    
      <br />
      {provide2 !== '' &&
      <div>
      <label className=' block font-medium mb-2'>Select Data plan</label>
      <select value={id2} onChange={handleSelect2} required className=' w-full border rounded p-3 mb-4'>
        <option value={''}>Select data plan</option>
        {plans.map((plan) =>{
          return  <option value={plan.planId} key={plan.planId}>{plan.planName}</option>
        })}
       </select>
      </div>
       
      }

      <CustomInput
      value={phone}
      onChange={setPhone}
       type='Phone number' 
       image={Code} 
       placeholder='Phone number' />
      <CustomInput 
      value={amount}
      onChange={setAmount}
      disabled= {true}
      type='Amount' 
      image={Naira} 
      placeholder='Amount' />
     
     <div className='my-5'></div>
        <CustomTextField
                    value={pin}
                    onChange={setPin}
                     type='password' label='PIN'/>
      <div className='w-full my-[10px] p-2 flex justify-between items-center'>
        <p>
          Add to Beneficiary
        </p>
        <CustomizedSwitches value={status} onChange={setStatus} />
      </div>
    </div>
    }
    
      
    </Modal>
  )
}

export default Data
