import React, { useState } from 'react'
import { Background } from '..'
import Logo from '../../../Assets/Images/logo.svg'
import CustomTextField from '../../../Components/Input/input'
import { Link } from 'react-router-dom'
import Loading from '../../../Components/Input/Loading'
import cookie from 'react-cookies'
import { ToastContainer, toast } from 'react-toastify';
import postService from '../../../Services/post-service'
import 'react-toastify/dist/ReactToastify.css';
import Button from '../../../Components/Input/Button'
import PasswordField from '../../../Components/Input/PasswordFieldC'

function Index() {
    const[email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('');
    const onSubmit = (e) => {
       e.preventDefault()
        setLoading(true)
        
        postService.userLogin(email, password).then(
          (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
              localStorage.setItem('userdetails', JSON.stringify(response.data.data))
              localStorage.setItem('accountStatuss',response.data.data.accountStatus)
              cookie.save("token", response.data.data.token, {
                path: "/",
                expires: new Date(Date.now() + 60 * 10 * 1000),
              });
              return   window.location.href = '/dashboard'
             }else{  
              toast.error(response.data.description,)
             }
             
            },
            (error) => {
              setLoading(false)
                return  toast.error('Login failed. Try again',)
              }
        )
        // navigate('/dashboard')
      }
      const handlePasswordChange = (e) => {
        const { value } = e.target;
        setPassword(value);
    
        // Define the regular expression for password validation
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    
        // Validate the password
        if (!passwordRegex.test(value)) {
          setError('Password must be at least 8 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character.');
        } else {
          setError('');
        }
      };
  return (
    <Background>
    <Loading open={loading}/>
    <ToastContainer/>
    <div className='w-full grid  place-items-center'>
        <img src={Logo} alt="Logo" />
    </div>

    <div>
        <div className='my-[18px] mb-3'>
            <h1 className='text-custom-black font-[700]'>Log in to your account</h1>
            <p className='text-custom-gray font-[600] text-[14px]'>Securely Log in to your account</p>
        </div>

        <form onSubmit={onSubmit} className='w-full '>

            <CustomTextField
                label="Email Address"
                 type="email"
                  margin="normal"
                    value={email}
                    onChange={setEmail}
                  />

                      <div className='my-5'>
                       <PasswordField
                        value={password}
                        label={'Password'}
                        onChange={handlePasswordChange}
                    />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                       </div>
              
                <Link className='text-primary mt-[10px] flex justify-center  mb-[40px] font-medium' to={'/forgetPassword'}>
                I forgot my password
                </Link>
                <div className=' grid grid-cols-1 my-3'>
                <Button
                name={'Log in'}
                />
                </div>
           
            

            <div className='font-medium flex justify-center mt-[10px] mb-[5px]'>
            <span>Don't have an account? {" "}   <Link className='text-primary my-[15px] mb-5 font-medium' to="/Signup">Sign Up</Link></span>
                
          
            </div>
            
        </form>
    </div>

</Background>
  )
}

export default Index

