import React from 'react'
import DashboardStructure from '..'

const NotLoggedIn = () => {
  return (
    <DashboardStructure bool={false}>
        
    </DashboardStructure>
  )
}

export default NotLoggedIn