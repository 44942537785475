'use-client'
import React from "react";
// styling
import './index.css'
// Routes
// eslint-disable-next-line
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Pages/Authentication/Login/index'
import Signup from './Pages/Authentication/Signup/index'
import TransactionPin from './Pages/Authentication/TransactionPin/index'
import VerifyPin from './Pages/Authentication/VerifyPin/index'
import ForgetPassword from './Pages/Authentication/ForgetPassword/index'
import ResetPassword from './Pages/Authentication/ResetPassword/index'
import Dashboard1 from './Pages/Dashboard/UserDashboard/NotLoggedIn'


import cookie from 'react-cookies'
import Dashboard from "./Pages/MainDashboard";
import UnauthorizedDashboard from "./Pages/Unauthorized/Dashboard";



const Logout = React.lazy(() => import('./Pages/logout'));
class App extends React.Component {

  constructor(props) {
    super(props);
    // var token = localStorage.getItem('token');
    var tokens = cookie.load('token')
    console.log(tokens)
    if(tokens === undefined){
      this.loggedIn = false;
     
    }else{
      this.loggedIn = true;
      // setTimeout(this.logout, 100000);
     
    }
  }

  logout() {
    // localStorage.removeItem('token');
    cookie.remove("token");
    localStorage.removeItem('userdetails');
    localStorage.removeItem('accountStatuss');
    this.loggedIn = false;
    window.location.href = '/';
  }
    render() {
    return this.loggedIn === true ? (
      <div>
      <BrowserRouter>
      <Routes>
        
        <Route path='/logout' element={ <Logout /> } />
        <Route path='*' element={ <Dashboard /> } />
      </Routes>

      
      </BrowserRouter>  
      </div>
    ): (
      <div>
      <BrowserRouter>
   
      <Routes>
        <Route path='/' element={<UnauthorizedDashboard/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path="/signup" element={<Signup/>} />
        <Route path="/transactionPin" element={<TransactionPin/>} />
        <Route path="/verifyPin" element={<VerifyPin/>} />
        <Route path="/forgetPassword" element={<ForgetPassword/>} />
        <Route path="/resetPassword" element={<ResetPassword/>} />
        <Route path="/topupCliq" element={<Dashboard1/>} />
        <Route path='*' element={<Login/>}/>
       
      </Routes>
      </BrowserRouter>  
      </div>
    )
      ;
  }
}

export default App;
