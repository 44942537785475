import React from 'react'

const Beneficiaries = () => {
    return (
        <div className="icon-container">
            <svg className="icon-svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.54 1.75H13.46L15.03 4.11C15.14 4.28 15.35 4.36 15.56 4.32L18.09 3.73L20.26 5.9L19.68 8.44C19.63 8.64 19.72 8.85 19.89 8.97L22.25 10.54V13.46L19.89 15.03C19.72 15.15 19.63 15.36 19.68 15.56L20.26 18.1L18.09 20.27L15.56 19.68C15.35 19.64 15.14 19.72 15.03 19.89L13.46 22.25H10.54L8.96 19.89C8.85 19.72 8.64 19.64 8.44 19.68L5.9 20.27L3.73 18.1L4.31 15.56C4.36 15.36 4.28 15.15 4.1 15.03L1.75 13.46V10.54L4.1 8.97C4.28 8.85 4.36 8.64 4.31 8.44L3.73 5.9L5.9 3.73L8.44 4.32C8.64 4.36 8.85 4.28 8.96 4.11L10.54 1.75ZM11.61 3.75L10.63 5.22C10.05 6.08 9 6.5 7.99 6.27L6.53 5.93L5.93 6.53L6.26 7.99C6.5 9 6.08 10.06 5.21 10.63L3.75 11.61V12.39L5.21 13.37C6.08 13.94 6.5 15 6.26 16.01L5.93 17.47L6.53 18.07L7.99 17.73C9 17.5 10.05 17.92 10.63 18.78L11.61 20.25H12.39L13.36 18.78C13.94 17.92 14.99 17.5 16.01 17.73L17.46 18.07L18.07 17.47L17.73 16.01C17.5 15 17.91 13.94 18.78 13.37L20.25 12.39V11.61L18.78 10.63C17.91 10.06 17.5 9 17.73 7.99L18.07 6.53L17.46 5.93L16.01 6.27C14.99 6.5 13.94 6.08 13.36 5.22L12.39 3.75H11.61ZM12 10.5C11.17 10.5 10.5 11.17 10.5 12C10.5 12.83 11.17 13.5 12 13.5C12.82 13.5 13.5 12.83 13.5 12C13.5 11.17 12.82 10.5 12 10.5ZM8.5 12C8.5 10.07 10.06 8.5 12 8.5C13.93 8.5 15.5 10.07 15.5 12C15.5 13.93 13.93 15.5 12 15.5C10.06 15.5 8.5 13.93 8.5 12Z" fill="#464858" />
            </svg>
        </div>
    )
}

export default Beneficiaries