import React, { useEffect, useState } from 'react';
import { Modal, Box, } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Deposit from '../../Assets/Images/deposit.svg'
import Card from '../../Assets/Images/card.svg'
import { Link } from 'react-router-dom';
import getServices from '../../Services/get-service';
import CustomInput from '../Input/custom.jsx'
import cancelIcon from '../../Assets/Images/cancelIcon.svg'
import Backbtn from '../../Assets/Images/back-nav.svg'
import { usePaystackPayment } from 'react-paystack';
import Naira from '../../Assets/Images/Naira.png'
import Button from '../Input/Button.js';
import { toast } from 'react-toastify';
import postService from '../../Services/post-service.js';
import Loading from '../Input/Loading.js';
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  // p: 4,
};

const FundWallet = ({ open, handleClose }) => {
    const [virtualAccount, setVirtualAccount] = useState({})
    const [show, setShow] = useState(false)
    const [amount, setAmount] = useState(0)
    const details = localStorage.getItem('userdetails')
    const user =  JSON.parse(details)
    const email = user.emailAddress;
    const [ref, setRef] = useState('')
    const [loading, setLoading] = useState(false)
    const initialConfig = { reference: null, email: email, amount: 0, publicKey:process.env.REACT_APP_PAYSTACK_KEY, };
    const [configP, setConfig] = useState(initialConfig);
    let initializePayment = usePaystackPayment(configP);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(virtualAccount?.accountNumber??'').then(() => {
          toast.success('Copied!');
           // Reset the message after 2 seconds
        }).catch(err => {
          
        });
      };
      const finalSubmit = (e) =>{
        e.preventDefault()
        console.log(amount)
        setLoading(true)
        postService.fundWallet(amount * 100,email).then(
          (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
              const reference = response.data.data.reference
              
              setConfig({
                ...configP, channel: [
                  'card',
                  'bank',
                  'ussd',
                  'qr',
                  'mobile_money',
                  'bank_transfer',
                  'eft',
                ], email: email, reference: reference, amount: amount * 100,
              });
           
             console.log(response.data.description)
             }else{
              toast.error(response.data.description)
             
             }
             
            },
            (error) => {
              setLoading(false)
                return  toast.error(error.message, {
                  position: toast.POSITION.TOP_CENTER
                });
              }
        )
      }
      const onSuccess = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        console.log( reference);
        console.log()
        setRef(reference.reference)
        setShow(true)
        // verifyPayment(reference.reference)
      };
      const handleClos = () => {
        setShow(false)
        verifyPayment(ref)
    }
    
      // you can call this function anything
      const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log('closed')
        window.location.reload()
      }
    
    // const initializePayment = usePaystackPayment(config);
  
    
  
    const verifyPayment = (ref) =>{
      setLoading(true)
      getServices.verifyFunding(ref).then(
        (response) => {
         setLoading(false)
          console.log(response.data)
          window.location.reload()
        },
        (error) => {
          setLoading(false)
          window.location.reload()
        }
      )
    }
    useEffect(() => {
      console.log(configP)
      if (configP.reference === null || configP.email === undefined) return;
      initializePayment(onSuccess, onClose)
    }, [configP])
    useEffect(() =>{
        getServices.getVirtualAccount().then(
            (response) => {
                console.log(response.data)
               
               if(response.data.status === '2000'){
                    setVirtualAccount(response.data.data)
               }else{
               
               }
               
              },
              (error) => {
               
                }
        )

    },[])
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
  

        <Box sx={modalStyle} className='rounded-lg p-5'>
        <Loading
          open={loading}
        />
        <header className='flex justify-between items-center mb-10 py-5 px-3 bg-white shadow-sm w-full fixed top-0 left-0 h-[60px] z-10 rounded-lg'>
        {show &&
            <p onClick={(e) =>setShow(false)} className='cursor-pointer'>
          <img src={Backbtn} alt="" />
        </p>
        }
        

        <h1 className='text-custom-black text-[18px] font-[700]'>
        Fund Wallet
        </h1>
        <Link className='cursor-pointer' onClick={handleClose}>
        <img src={cancelIcon} alt="" />
        </Link>

      </header>
        <div  className='w-full max-h-[550px] mt-14 overflow-scroll relative'>
        <div>
      
        </div>
                {!show &&
                    <div>
                        <h1 className='font-[600]'>Fund Wallet</h1>
                        <p className='text-[15px] font-[500] text-custom-gray'>Select how you want to fund your wallet</p>
                    </div>
                }
                {!show &&
                    <div className='py-4'>
                        <div onClick={() => setShow(true)} className='flex items-center py-4 w-full rounded px-2 bg-[#F2F4F7] border-solid border-[1px] border-[#F2F4F7] hover:bg-secondary/[.2] hover:border-solid hover:border-[1px] hover:border-secondary/[.5] ease-linear'>
                            <img src={Deposit} alt="" className='mr-2' />
                            <p className='ml-2 font-[500]'>Bank Transfer</p>
                        </div>
                        {!show &&
                            <div className='flex items-center py-4 w-full rounded px-2 bg-[#F2F4F7] border-solid border-[1px] border-[#F2F4F7] hover:bg-secondary/[.2] hover:border-solid hover:border-[1px] hover:border-secondary/[.5] ease-linear mt-[15px]'>
                            <img src={Card} alt="" className='mr-2' />
                            <p className='ml-2 font-[500]'>Card</p>
                        </div>
                        }
                       
                    </div>
                }
                    {!show &&
                        <CustomInput 
                        valuee={amount}
                        onChange={setAmount}
                        type='Amount' image={Naira} />
                    }

                    {
                    show &&
                    <div>
                    <img src={Deposit} alt="" className='mx-auto' />
                    <p className='ml-2 font-[700] my-4 text-center'>Virtual account</p>
                    <p className='ml-2 font-[400] mb-5 text-center'>Money transfers sent to this bank account number will automatically fund your  wallet.</p>
                    <p className='ml-2 font-[700] text-primary my-2 text-center' onClick={copyToClipboard}> {virtualAccount?.accountNumber??''} <ContentCopyIcon/></p>
                    <p className='ml-2 font-[700] capitalize my-2 text-center'>Bank name: {virtualAccount?.bankName??''}</p>
                    <p className='ml-2 font-[700] capitalize my-2 text-center'>Account name: {virtualAccount?.accountName??''}</p>

                    </div>
                    }
                    {!show &&
                    <div className=' grid grid-cols-1'>
                        <Button
                            name={'Continue'}
                            onClick={finalSubmit}
                        />
                    </div>
                    }
                </div>
        </Box>
    </Modal>
  );
};

export default FundWallet;
