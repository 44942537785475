import React, { useState } from 'react'
import Modal from '../Card/modalCont'
import cancelIcon from '../../Assets/Images/cancelIcon.svg'
import Select from '../Input/select'
import CustomInput from '../Input/custom.jsx'
import Code from '../../Assets/Images/Code.png'
import Naira from '../../Assets/Images/Naira.png'
import CustomizedSwitches from '../Input/switch.jsx'
import CustomTextField from '../Input/input.jsx'
import postService from '../../Services/post-service.js'
import { toast } from 'react-toastify'
import Loading from '../Input/Loading.js'

const Airtime = ({closeIcon}) => {
  const [provide,setProvide] = useState('')
  const [phone,setPhone] = useState('')
  const [amount, setAmount] = useState('')
  const [pin, setPin] = useState('')
  const [status, setStatus] = useState(false)
  const [loading, setLoading] = useState(false)

  const selectProviders = [
    {
      value: "MTN",
      label: "MTN",
    },
    {
      value: "Airtel",
      label: "Airtel",
    },
   
    {
      value: "Glo",
      label: "Glo",
    },
    {
      value: "9mobile",
      label: "9mobile",
    },
  ];
  
  const onSubmit = (e) =>{
    e.preventDefault()
    setLoading(true)
    postService.buyAirtime(amount,pin,phone,provide,status).then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
            toast.success(response.data.description)
            setTimeout(() => {
              window.location.reload()
            }, 2000);
           
           }else{  
          
            toast.error(response.data.description,)
           }
           
          },
          (error) => {
            setLoading(false)
              return  toast.error('Login failed. Try again',)
            }
      )
}
  return (
    <Modal cancelbtn={cancelIcon}  text='pay' onSubmit={onSubmit} title='Buy Airtime' hide='hide' handleClose={closeIcon}>
    <Loading
      open={loading}
    />
      <Select 
      onChange={setProvide}
      value={provide}
      selectProviders={selectProviders} selectText='Select network provider' width='100%'/>
      <div>
      {/* <select
      value={provide}
      onChange={(e) =>setProvide(e.target.value)}
      >
        {selectProviders.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select> */}
      </div>
      <CustomInput type='Phone number'
      value={phone}
      onChange={setPhone}
       image={Code} />
      <CustomInput
        value={amount}
        onChange={setAmount}
       type='Amount' image={Naira} />
       <div className='my-5'></div>
        <CustomTextField
                    value={pin}
                    onChange={setPin}
                     type='password' label='PIN'/>
      <div className='w-full my-[10px] p-2 flex justify-between items-center'>
        <p>
          Add to Beneficiary
        </p>
        <CustomizedSwitches value={status} onChange={setStatus} />
      </div>
   
    </Modal>
  )
}

export default Airtime
