import axios from "axios";
import authHeader from "./AuthHeader";
import cookie from 'react-cookies'
const signupUser = (bvn, email, referral, pNum, password ) =>{
    const value = {
        "emailAddress": email,
        "bvn":bvn,
        "password": password,
        "phoneNumber": pNum,
        "referalId": referral
      }
    return axios.post('/api/verify-email-signup-new', value , { headers: authHeader()});
}
const verifyEmail = (otp, requestId) =>{
    const value ={
        "otp": otp,
        "requestId": requestId
      }

    return axios.post('/api/topupcliq-verify-email', value, { headers: authHeader()})
}


const setupTransactionPin = ( pim) =>{
    const tokens = cookie.load('jwttoken')
    const value = { "transactionPin":pim}

    return axios.post('/api/set-pin', value,{
        headers: {
          'Authorization': tokens 
        }
      } )
}
const resendOtp = (email) =>{
    return axios.post(`/api/re-sendOtp?email=${email}`, { headers: authHeader()})
}
const forgetPassword = (email) =>{

    return axios.post(`/api/topupcliq-forgot-password?email=${email}`)
}
const userLogin = (email, password) =>{
    const value = {"emailAddress": email, "password": password, "channel": "web",}
    return axios.post(`/api/topupcliq-user-login`, value, { headers: authHeader()})
}


const bankInfo = (accountNumber, bankName, transactionPin)=>{
        const value = { 'accountNumber': accountNumber, 'bankName': bankName, 'transactionPin': transactionPin}
        return axios.post(`/api/edit-bank-info`, value, { headers: authHeader()})
}
//api to change password
const changePin = (newPin, oldPin) =>{
    const value ={
        "oldPin": oldPin,
        "newPin": newPin
      }
    return axios.post(`/api/topupcliq-change-pin`, value, { headers: authHeader()})
}

//api to change password
const changePassword =(pin,password) =>{
    const value = {
        "transactionPin": pin,
        "newPassword": password
      }
    return axios.post(`/api/topupcliq-change-password`, value, { headers: authHeader()})
}

const resetPassword =(otp,requestId, password, email) =>{
    const value = {
      "emailAddress":  email,
      "otp": otp,
      "password": password,
      "requestId": requestId,
    }
    return axios.post(`/api/topupcliq-reset-password`, value, { headers: authHeader()})
}
const addBankAcc =(accountNumber,bankName, transactionPin, bankCode) =>{
    const value = {"accountNumber": accountNumber, "bankName": bankName, "transactionPin": transactionPin, "bankCode": bankCode}
    return axios.post(`/api/add-bank-info`, value, { headers: authHeader()})
}
const forgetPin =() =>{

    const value = {}
    return axios.post(`/api/forgot-pin`, value, { headers: authHeader()})
}
const resetPin = (otp,requestId,pin) =>{
    const value = {"otp": otp, "requestId": requestId, "pin": pin}
    return axios.post(`/api/reset-pin`, value, { headers: authHeader()})
}

const fundWallet = (amount, email) =>{
    const value= {
        "amount": amount,
        "channel": [
          "card", "bank", "ussd", "qr", "mobile_money", "bank_transfer", "eft"
        ],
        "email": email,
        "callback_url":  'https://hello.pstk.xyz/callback'
      };

    return axios.post(`/api/topupcliq-fund-wallet`, value, { headers: authHeader()})
}
const Withdrawer = (amount, pin, reason) =>{
    const value = {"reason": reason,"amount":amount,"transactionPin":pin}
    return axios.post(`/api/initiate-transfer`, value, { headers: authHeader()})
}

const sendMoney = (amount, pin, email, description) =>{
    const value = {
        "amountfunded": amount,
      "description": description,
        "recieverEmailAddress": email,
        "transactionPin": pin,
        "deviceId": 'deviceId'
      }
    return axios.post(`/api/transfer-fund`, value, { headers: authHeader()})
}

const buyAirtime = (amount, pin, number, network,saveStatus) =>{
    const value = {
        "amount": amount,
        "beneficiary": number,
        "network": network,
        "deviceId": 'deviceId',
        "transactionPin": pin,
        "saveStatus":saveStatus
      }
    return axios.post(`/api/topupcliq-airtime`, value, { headers: authHeader()})
}

const buyData = (amount, pin, number, network,id,saveStatus) =>{
    const value = {
        "amount": amount,
        "beneficiary": number,
        "network": network,
        "deviceId": 'deviceId',
        "transactionPin": pin,
        "id": id,
        "saveStatus":saveStatus
      }
    return axios.post(`/api/topupcliq-data`, value, { headers: authHeader()})
}

const purchaseSmeData = (amount, pin, number,id,saveStatus) =>{
const value = {
  "amount": amount,
  "phone_number": number,
  "plan_id": id,
    "transactionPin": pin,
    "saveStatus":saveStatus
  }
  return axios.post(`/api/topupcliq-data`, value, { headers: authHeader()})
}

const purchaseElectricity = (amount, pin, service_type,account_number,phone) =>{
  const value ={
    "service_type": service_type,
    "account_number": account_number,
    "amount": amount,
    "phone": phone,
    "agentId": "205",
    "transactionPin":pin
  }
    return axios.post(`/api/electricity-request`, value, { headers: authHeader()})
  }

  const nameLookupElectricity = (type, number) =>{
    const value ={
      "service_type": type,
      "account_number" : number,
    };
      return axios.post(`/bills/verify-name-elec`, value, { headers: authHeader()})
    }
  
    const cablePriceList = (name) =>{
      const value ={"service_type": name};

        return axios.post(`/bills/multichoice-list`, value, { headers: authHeader()})
      }
      const dstvNameLookup = (type, number) =>{
        const value ={
          "service_type": type,
          "account_number" : number,
        };
          return axios.post(`/bills/look-up`, value, { headers: authHeader()})
        }
        const purchaseCable = (amount, pin, service_type,account_number,code) =>{
          const value ={
            "smartcard_number": account_number,
            "total_amount": amount,
            "product_code": code,
            "product_monthsPaidFor": 1,
            "service_type": service_type,
            "agentId": "205",
            "transactionPin":pin
          }
            return axios.post(`/api/cable-request`, value, { headers: authHeader()})
          }
          const purchaseWithoutLogin = (value) =>{
          
              return axios.post(`/api/webapp-fund-wallet`, value, { headers: authHeader()})
            }
            
const postService ={
    signupUser,
    verifyEmail,
    setupTransactionPin,
    resendOtp,
    userLogin,
    forgetPassword,
    bankInfo,
    changePin,
    changePassword,
    resetPassword,
    addBankAcc,
    forgetPin,
    resetPin,
    fundWallet,
    Withdrawer,
    sendMoney,
    buyAirtime, 
    buyData,
    purchaseSmeData,
    purchaseElectricity,
    nameLookupElectricity,
    cablePriceList,
    dstvNameLookup,
    purchaseCable,
    purchaseWithoutLogin
}

export default postService