import React, { useState } from 'react'
import { Background } from '..'
import Logo from '../../../Assets/Images/logo.svg'
import CustomTextField from '../../../Components/Input/input'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import postService from '../../../Services/post-service'
import Button from '../../../Components/Input/Button'
import Loading from '../../../Components/Input/Loading'

const Index = () => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const onSubmit = e => {
       e.preventDefault()

        setLoading(true)
        postService.forgetPassword(email).then(
          (response) => {
              console.log(response.data)
              setLoading(false)
             if(response.data.status === '2000'){
              localStorage.setItem('requestId', response.data.data.requestId)
              localStorage.setItem('email', email)
              return toast.success('Otp sent to your mail')
              .then((value) => {
                navigate('/resetPassword')
              }); 
             }else{
              toast.error(response.data.description)
             
             }
             
            },
            (error) => {
              setLoading(false)
                return  toast.error('Password reset failed')
                
              }
        )
            // navigate('/resetpassword')
      }
    return (
        <Background padding="none">
        <Loading open={loading}/>
        <ToastContainer/>
            <div className='w-full flex items-center justify-between bg-white p-3 font-[700] text-custom-black text-[1.4rem]'>
                <Link to="/Signup" className='font-bold text-3xl cursor-pointer ml-[-10px]'>
                    &larr;
                </Link>

                <p>Forgot password</p>

                <Link className='font-bold text-3xl cursor-pointer'>
                    &times;
                </Link>
            </div>

            <div className='w-full grid place-items-center sticky top-0 z-50 bg-white p-3'>
                <img src={Logo} alt="Logo" />
            </div>

            <form action="" onSubmit={onSubmit} className='w-full '>
                <CustomTextField
                    label="Email Address"
                     type="email" 
                     value={email}
                     onChange={setEmail}
                     margin="normal" />
                <br />
                <div className=' grid grid-cols-1 my-3'>
                <Button
                name={'Reset Password'}
                />
                </div>
            </form>
        </Background>
    )
}

export default Index