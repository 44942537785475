import React from 'react'

const customInput = ({type, image, placeholder, onChange, valuee, ...rest}) => {
    // const handleInputChange = (event) => {
    //     const { value } = event.target;
    //     const parsedValue = parseInt(value);
    
    //     if (!isNaN(parsedValue) && parsedValue >= 0) {
    //       onChange(parsedValue.toString());
    //     }
    //   };
    //   const handleKeyDown = (event) => {
    //     if (event.key === '-' || event.key === '-') {
    //       event.preventDefault();
    //     }
    //   };
    return (
        <div className='my-3'>
            <p className='font-[500]'>
                {type}
            </p>

            <div className='w-full h-[50px] rounded flex bg-[#F2F4F7] border-solid border-[1px] border-custom-gray/[.2]'>
                <div className='w-[20%] border-solid border-1 '>
                    <img src={image} alt="" className='w-[55%] h-[55%] mt-[10px] block m-auto ' />
                </div>
                <input
                value={valuee}
                onChange={(e) => onChange(e.target.value)}
                required
                {...rest}
                // min="0"
                type="text" name="" id="" placeholder={placeholder} 
                className='w-[80%] outline-none bg-transparent border-solid border-1 border-black' />
            </div>
        </div>
    )
}

export default customInput
