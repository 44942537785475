import React, { useState } from 'react'
import { Background } from '..'
import Logo from '../../../Assets/Images/logo.svg'
import CustomTextField from '../../../Components/Input/input'
import { Link, useNavigate } from 'react-router-dom'
import PasswordField from '../../../Components/Input/PasswordFieldC'
import { ToastContainer, toast } from 'react-toastify'
import Button from '../../../Components/Input/Button'
import postService from '../../../Services/post-service'
import Loading from '../../../Components/Input/Loading'

const Index = () => {
    const [bvn, setBvn] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')
    const [referal, setReferral] = useState('')
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);

    // Define the regular expression for password validation
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    // Validate the password
    if (!passwordRegex.test(value)) {
      setError('Password must be at least 8 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character.');
    } else {
      setError('');
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (error) {
      toast.success('Please fix the errors before submitting.');
    } else {
        setOpen(true)
        postService.signupUser(bvn,email,referal,phoneNumber,password).then(
            (response) => {
                console.log(response.data)
                setOpen(false)
               if(response.data.status === '2000'){
                localStorage.setItem('user', JSON.stringify(response.data.data))
                sessionStorage.setItem('sEmail', email)
                return toast.success(response.data.description, {
                               
                  onClose: () => {
                    navigate('/verifyPin')
                  }
                })
                
               }else{
                toast.error(response.data.description)
               
               }
               
              },
              (error) => {
                setOpen(false)
                  return   toast.error('Signup failed!!')
                }
          )
      
    }
  };
    return (
        <Background padding="none">
        <Loading open={open}/>
        <ToastContainer/>
            <div className='w-full grid place-items-center sticky top-0 z-50 bg-white p-3'>
                <img src={Logo} alt="Logo" />
            </div>

            <div className='my-[18px] mb-3'>
                <h1 className='text-custom-black font-[700]'>Create account</h1>
            </div>
            <form onSubmit={handleSubmit} action="" className='w-full '>
                <CustomTextField
                    label="BVN" 
                    type="text"
                     margin="normal"
                     value={bvn}
                     onChange={setBvn}
                      />
                <CustomTextField
                    label="Email Address" 
                    type="text"
                     margin="normal" 
                        value={email}
                        onChange={setEmail}
                     />
            
                <CustomTextField
                    label="Phone Number"
                     type="text"
                      margin="normal"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                       />
                       <CustomTextField
                        label="Referral"
                        type="text"
                        margin="normal"
                        value={referal}
                        onChange={setReferral}
                       />
                       <div className='my-5'>
                       <PasswordField
                        value={password}
                        label={'Password'}
                        onChange={handlePasswordChange}
                    />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                       </div>
                  
                    
                <br />
                <div className=' grid grid-cols-1'>
                <Button
                    name={'Signup'}
               />
                </div>
              
                <div className='font-medium mt-[10px] flex justify-center mb-[5px]'>
                    
                    <p>Have an account? {" "} <Link className='text-primary my-[15px] mb-5 font-medium' to="/login">Log in</Link></p>    
                    
                    </div>
            </form>
        </Background>
    );
}

export default Index;