import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import editPencil from '../../Assets/Images/pencil-line.svg'
import CustomTextField from '../Input/input.jsx'
import { PrimaryBtn } from '../Button/button.jsx'

const Profile = () => {
    const details = localStorage.getItem('userdetails')
    const user =  JSON.parse(details)
    useEffect(() =>{
            setFname(user.firstName)
            setLname(user.lastName)
            setEmail(user.emailAddress)
            setPhone(user.phoneNumber)
    },[user])
    const [firstname, setFname] = useState('')
    const [lastname, setLname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    return (
        <div>
            <div className='w-full flex justify-between items-center p-3 pb-6'>
                <div>
                    <h1 className='font-[600] text-[20px]'>Personal Info</h1>
                    <p className='text-[16px] font-[400] mt-1'>Update your photo and personal details here</p>
                </div>
                <Link className='text-primary flex items-center font-[600]'>
                    Edit
                    <img src={editPencil} alt="" className='ml-1' />
                </Link>
            </div>
            <hr />

            <form>
                <div className='flex items-center p-3'>
                    <h1 className='w-[30%]'>Name</h1>

                    <div className='flex w-[55%] justify-between'>
                        <div className='mr-4 w-[60%]'>
                            <CustomTextField 
                            type='text'
                             label='First name' 
                                disable={true}
                                value={firstname}
                                onChange={setFname}
                             />
                        </div>

                        <div className='w-[60%]'>
                            <CustomTextField 
                            type='text'
                            disable={true}
                                value={lastname}
                                onChange={setLname}
                             label='Last name' />
                        </div>
                    </div>
                </div>
                <hr />
                <div className='flex items-center p-3'>
                    <h1 className='w-[30%]'>Email Address</h1>
                    <div className='w-[55%]'>
                        <CustomTextField
                         type='text'
                          label='Email'
                          disable={true}
                                value={email}
                                onChange={setEmail}
                           />
                    </div>
                </div>
                <hr />
                <div className='flex items-center p-3'>
                    <h1 className='w-[30%]'>Phone Number</h1>
                    <div className='w-[55%]'>
                        <CustomTextField 
                        type='text'
                        value={phone}
                        onChange={setPhone}
                         label='Phone Number' />
                    </div>
                </div>
                <hr />
                {/* <div className='flex items-center p-3'>
                    Drag and Drop file in here
                </div> */}
            </form>
            <hr />


            {/* <div className='flex justify-end items-center p-3'>
                <div className='mr-3'>
                    <PrimaryBtn variant="outlined" text='Cancel' />
                </div>

                <PrimaryBtn variant='contained' text='Change' />
            </div> */}
        </div>
    )
}

export default Profile
