import React, { useState } from 'react'
import { Background } from '..'
import Logo from '../../../Assets/Images/logo.svg'
import CustomTextField from '../../../Components/Input/input'
import PasswordField from '../../../Components/Input/PasswordFieldC'
import Button from '../../../Components/Input/Button'
import { ToastContainer, toast } from 'react-toastify'
import Loading from '../../../Components/Input/Loading'
import postService from '../../../Services/post-service'
import { useNavigate } from 'react-router'

const Index = () => {
    const [otp, setOtp] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState('')
    const requestId = localStorage.getItem('requestId')
    const email = localStorage.getItem('email')
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setPassword(value);
    
        // Define the regular expression for password validation
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    
        // Validate the password
        if (!passwordRegex.test(value)) {
          setError('Password must be at least 8 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character.');
        } else {
          setError('');
        }
      };
      const onSubmit = (e) =>{
        e.preventDefault()
        if(!error){
          setLoading(true)
            postService.resetPassword(otp,requestId,password,email).then(
              (response) => {
                
                  console.log(response.data)
                 
                 if(response.data.status === '2000'){
                 setLoading(false)
                  return  toast.success('Password changed successfully').then((e)=>{
                    navigate('/')
                  }
                  
                  )
                 
                 }else{
                  setLoading(false)
                  toast.error(response.data.description)
                 
                 }
                 
                },
                (error) => {
                  setLoading(false)
                    return  toast.error('Change of pin Failed')
                  })
        }else{
          toast.error('Password not a match')
        }
       
    }

    return (
        <Background>
        <ToastContainer/>
        <Loading open={loading}/>
            <div className='w-full grid place-items-center sticky top-0 z-50 bg-white p-3'>
                <img src={Logo} alt="Logo" />
            </div>
            <form action="" onSubmit={onSubmit} className='w-full flex flex-col justify-between items-center'>
                
                <CustomTextField
                    label="OTP"
                     type="text"
                      margin="normal" 
                     value={otp}
                     onChange={setOtp}
                    />
                <div className='my-5'>
                       <PasswordField
                        value={password}
                        label={'Password'}
                        onChange={handlePasswordChange}
                    />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                       </div>
                <br />
                <div className=' grid grid-cols-1 my-3'>
                <Button
                name={'Reset Password'}
                />
                </div>
            </form>
        </Background>
    )
}

export default Index