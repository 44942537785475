import React, { useEffect, useState } from 'react'
import Naira from '../../Assets/Images/Naira.png'
import Modal from '../Card/modalCont'
import CustomInput from '../Input/custom.jsx'
import CustomTextField from '../Input/input.jsx'
import CustomizedSwitches from '../Input/switch.jsx'
import cancelIcon from '../../Assets/Images/cancelIcon.svg'
import getServices from '../../Services/get-service.js'
import { toast } from 'react-toastify'
import postService from '../../Services/post-service.js'
import Loading from '../Input/Loading.js'

const Electricity = ({ closeIcon }) => {
  const [loading, setLoading] = useState(false)
  const [electricityList, getElectricityList] = useState([])
  const [metreNo, setMetreNo] = useState('')
  const [name, setName] = useState('')
  const [amount, setAmount] = useState('')
  const [id, setId] = useState('')
  const [pin, setPin] = useState('')
  const [phone, setPhone] = useState('')
  const [show,setShow] = useState(true)
  const [data, setData] = useState({})
  const [status, setStatus] = useState(false)
  useEffect(() =>{
    
      setLoading(true)
      getServices.getElectricity().then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
            getElectricityList(response.data.data)
           
           }else{  
          
            toast.error(response.data.description,)
           }
           
          },
          (error) => {
            setLoading(false)
              return  toast.error('Login failed. Try again',)
            }
      )
  
    
   },[])
 
  useEffect(() =>{
    if(metreNo.length > 7){
      postService.nameLookupElectricity(id,metreNo).then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
            
           setName(response.data.data[0].customerName)
           }else{  
            // toast.error(response.data.description,)
           }
           
          },
          (error) => {
            
            }
      )
    }
    
  },[metreNo,id])

  const onSubmit = (e) =>{
    e.preventDefault()
    setLoading(true)
    postService.purchaseElectricity(amount,pin,id,metreNo,phone).then(
        (response) => {
            console.log(response.data)
            setLoading(false)
           if(response.data.status === '2000'){
            setShow(true)
            setData(response.data.data)
            toast.success(response.data.description)

            
           }else{  
            toast.error(response.data.description,)
           }
           
          },
          (error) => {
            setLoading(false)
              return  toast.error('Login failed. Try again',)
            }
      )
  }
  

  return (
    <Modal cancelbtn={cancelIcon} onSubmit={show ?onSubmit:null} text='pay' title='Electricity Payment' hide='hide' handleClose={closeIcon}>
      {/* <Select selectProviders={selectBillers} selectText='Selecta biller' width='100%' /> */}
      <Loading
        open={loading}
      />
      {show &&
      <div>
      <div>
      <label className=' block font-medium mb-2'>Select Electricity plan</label>
      <select value={id} onChange={(e) => setId(e.target.value)} required className=' w-full border rounded p-3 mb-4'>
        <option value={''}>Select data plan</option>
        {electricityList.map((plan) =>{
          return  <option value={plan.serviceType} key={plan.productId}>{plan.name}</option>
        })}
       </select>
      </div>
      <CustomTextField type='text'
        value={metreNo}
        onChange={setMetreNo}
       label='Meter number' />
       {name.length>0 &&
       <div className=' my-5'>
            <p>Name: {name}</p>
       </div>
       }
      <CustomInput type='Amount'
        valuee={amount}
        onChange={setAmount}
       image={Naira} 
       placeholder='Amount' />
       
       <CustomTextField type='text'
        value={phone}
        onChange={setPhone}
       label='Phone number' />
        <div className='my-5'></div>
        <CustomTextField
        required
        value={pin}
         onChange={setPin}
          type='password' label='PIN'/>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
      <div className='w-full my-[10px] p-2 flex justify-between items-center'>
        <p>
          Add to Beneficiary
        </p>
        <CustomizedSwitches value={status} onChange={setStatus} />
      </div>
      </div>
      }
      {!show &&
      <div>
      <h1>Payment successful</h1>
        <p className=' text-center my-3'>Amount: {data?.amount}</p>
        <p className=' text-center my-3'>Token: {data?.creditToken}</p>
        <p className=' text-center my-3'>Value: {data?.amountOfPower}</p>
      </div>
      }
     
    </Modal>
  )
}

export default Electricity
