import React from 'react'
import { Route, Routes } from 'react-router'
import Transactions from '../../Pages/Transactions/index'
import Beneficiaries from '../../Pages/Beneficiaries/index'
import Settings from '../../Pages/Settings/index'
import LoggedInUser from '../../Pages/Dashboard/UserDashboard/LoggedIn'
function Routess() {
  return (
    <div>
        <Routes>
        <Route path='/dashboard' element={<LoggedInUser/>}/>
        <Route path="/transactions" element={<Transactions/>} />
        <Route path="/beneficiaries" element={<Beneficiaries/>} />
        <Route path="/settings" element={<Settings/>} />
      </Routes>
    </div>
  )
}

export default Routess